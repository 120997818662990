
<template>

  <div class="wrapper">
    <div class="animated fadeIn">
      <!-- 판매관리 --------------------------------------------------------------------------------------------->
      <BCard>
        <BCard class="mb-1" footer-tag="footer" header-tag="header">

          <div slot="header">
            <BIconFileEarmarkRuledFill/> <strong> 세금계산서 발행대상 주문관리 </strong>
            <div class="card-header-actions">
              <small class="text-muted">세금계산서 발행 관리</small>
            </div>
          </div>

          <BRow class="mb-2">
            <BCol>
              <BInputGroup size="sm">
                <BFormSelect v-model="dateGbn"
                             :options="dateGbnOpt"
                             size="sm"
                             style="max-width: 20%"
                             @input="setDateGbn"/>

                <BInputGroupAppend>
                  <BFormInput v-model="fromDate"
                              v-b-tooltip.hover
                              size="sm"
                              title="Enter 검색" type="date"
                              @change="getSalesOrderList"/>

                </BInputGroupAppend>
                <BInputGroupAppend>
                  <BFormInput v-model="toDate"
                              v-b-tooltip.hover
                              size="sm"
                              title="Enter 검색" type="date"
                              @change="getSalesOrderList"/>
                </BInputGroupAppend>
              </BInputGroup>
            </BCol>
            <BCol>
              <BInputGroup>
                <BFormSelect v-model="searchField"
                             :options="searchOpts"
                             style="max-width: 20%"
                             size="sm"/>
                <BInputGroupAppend>
                  <BFormInput v-model="searchWord"
                              v-b-tooltip.hover
                              size="sm"
                              title="Enter 검색" type="text"
                              @keyup.enter="getSalesOrderList"/>
                </BInputGroupAppend>
                <BInputGroupAppend>
                  <BButton size="sm" variant="primary"
                           @click="getSalesOrderList">검 색</BButton>
                </BInputGroupAppend>

              </BInputGroup>

            </BCol>
            <BCol cols="2">
              <BInputGroup>
                <BFormSelect v-model="perPage" :options="[10,20,30,50,100,200]" size="sm"/>
                <BInputGroupAppend>
                  <BButtonGroup>
                    <BButton size="sm" variant="primary" @click="getSalesOrderList">
                      <BIconArrowRepeat/>
                    </BButton>
                    <BButton class="ml-1" size="sm" variant="info" @click="exportExcel">
                      <BIconFileSpreadsheetFill/>
                      xlsx
                    </BButton>
                  </BButtonGroup>
                </BInputGroupAppend>
              </BInputGroup>

            </BCol>

          </BRow>

          <BRow>
            <BCol>
              <vue-excel-editor
                  ref="orderGrid"
                  v-model="flattenedData"
                  :localized-label="$localizedLabel"
                  :page="perPage"
                  :readonly-style="{backgroundColor:'#EFE'}"
                  class="mb-1"
                  filter-row
                  width="100%"
                  @update="updateSalesOrder">
<!--                <vue-excel-column field="_id" label="주문번호" readonly type="string" width="80px" :to-text="v=>{return v?v.slice(-6).toUpperCase():'N/A'}"/>-->
                <vue-excel-column field="userId" label="주문자ID" width="110px"/>
                <vue-excel-column field="name" label="주문자" width="80px"/>
                <!--                <vue-excel-column field="salesMaster" label="상품명" width="200px" :to-text="v=>{ return v? v.title:''}"/>-->
                <!--                <vue-excel-column field="products" label="주문제품" width="200px" :to-text="v=>{ return v.length?v.map(i=>{return i.prd_name}):''}" readonly/>-->
                <vue-excel-column field="amount" label="금액" readonly type="number" width="80px" :to-text="toComma"/>
                <vue-excel-column field="payAmount" label="결제액" type="number" width="80px" :to-text="toComma" :to-value="toNumber"/>
                <vue-excel-column field="payStat" :options="payStatOpts" label="지불상태" readonly type="map" width="80px"/>
                <vue-excel-column field="payType" :options="payTypeOpts" label="결제" type="map" width="140px"/>

                <vue-excel-column field="taxInvoiceYn" :options="isTaxOpts" label="계산서 발행여부" type="map" width="120px"/>
                <vue-excel-column field="depositor" label="입금자"  width="100px"/>
                <vue-excel-column field="phoneNo" label="연락처" width="130px"/>
                <vue-excel-column field="email" label="Email" width="200px"/>

                <vue-excel-column field="compNo" label="사업자번호" width="120px"/>
                <vue-excel-column field="compName" label="회사명" width="200px"/>
                <vue-excel-column field="prName" label="대표자명" width="90px"/>
                <vue-excel-column field="address" label="사업장주소" width="500px"/>
                <vue-excel-column field="bizKind" label="업태/업종" width="110px"/>

                <!--                <vue-excel-column field="pgTran" label="TR_ID" readonly type="number" width="60px" />-->
                <!--                <vue-excel-column field="payDt" label="결제일" type="date" width="88px" readonly :to-text="toYmdHm"/>-->
                <!--                <vue-excel-column field="approveYn" label="승인" type="checkYN" width="50px"/>-->
                <!--                <vue-excel-column field="approveDt" label="승인일시" readonly type="datetime" width="140px"/>-->
                <!--                <vue-excel-column field="cancelYn" label="취소" readonly type="checkYN" width="40px"/>-->
                <!--                <vue-excel-column field="cancelDt" label="취소일" readonly type="date" width="90px"/>-->
                <vue-excel-column field="comment" label="비고" width="200px"/>
                <vue-excel-column field="createdAt" label="등록일" type="datetime" width="140px" :to-text="toYmdHm" readonly/>
                <!--                <vue-excel-column field="regId" label="등록자" readonly width="95px"/>-->
                <!--                <vue-excel-column field="createdAt" label="등록일" readonly type="date" width="95px"/>-->
                <!--                <vue-excel-column field="updId" label="수정자" readonly width="95px"/>-->
                <!--                <vue-excel-column field="updatedAt" label="수정일" readonly type="date" width="95px"/>-->
              </vue-excel-editor>
            </BCol>
          </BRow>

          <BRow>
            <BCol>
              <BProgress :max="totalCount" :value="progCount" height="2rem" show-value variant="warning"/>
            </BCol>
          </BRow>
        </BCard>

        <div class="mt-2" style="color: #6a6a6a;">
          <p class="mb-0" style="color: #e62626;">ⓘ <strong>[사용시 확인사항]</strong> <span>(24.11)</span> </p>
          <p class="ml-2 mb-0">- <strong>[상품주문관리]</strong> 데이터와 연동되며 주의가 필요합니다.</p>
          <p class="ml-2 mb-0">- 세금계산서 발행된 주문은 혼동되지 않도록 <strong>"계산서 발행여부"</strong> 상태 변경해주세요 :D</p>
          <p class="ml-2 mb-0">- 발행대상 <strong>(현금결제건 & 결제완료건 & 사업자주문건)</strong> 만 조회됩니다.</p>
        </div>

      </BCard>

    </div>
  </div>
</template>






<script>

//-------------------------------------------------------------------------------------------------
import {
  alertSync,
  alertConfirm,
  apiCall,
  cloneVar,
  checkBisNo,
  toastSync,
  alertWarn,
  commify,
  numberfy, sleep
} from '../../../common/utils';
import qs from 'querystring';
import moment from "moment";
// import '../../../common/HelperMixin';


const _sales_order = {
  userId       : '', // 구매자 ID
  userNo       : '',
  salesMaster  : null,
  xcostUser    : null,
  xcostPayment : null,
  pgTran       : null,
  orderType    : 'GENERAL', // 일반,업그레이드 UPGRADE
  loginId      : '', // 이용자 ID
  products     : [],
  amount         : 0, // *구매 총액(할인율적용)
  orderToken     : null, // 구매 토큰
  payType        : '0000', // 결제대기
  payStat        : '99', // 결제 상태 : 99-미결제, 00-결제완료
  payAmount      : 0, // 결제금액
  payDt          : null, // 결제일시
  trsNo          : 0,
  email          : '',
  name           : '', // 구매자명
  prName         : '', // 대표자명
  phoneNo        : '', // 구매자 연락처
  depositor      : '', // 입금자명
  address        : '',
  zipCode        : '',
  taxStat        : '03', // 사업자상태 '01':'사업자', '03':'개인', '04':'영수증발행대상'
  compNo         : '',
  compName       : '',
  bizKind        : '', // 업종 업태
  cancelYn       : 'N' ,
  cancelDt       : null,
  cancelDesc     : '',
  approveYn      : 'N', // 주문승인
  approveDt      : null, // 주문승인일시
  description    : '',
  comment        : '',
  remark         : '', // 리마크
  updId          : '',
  regId          : '',
  business       : {}
};

const payTypeOpts =  {
  '9999': '[9999] 주문생성',
  '0000': '[0000] 결제대기',
  '1000': '[1000] 현금(계좌)',
  '0001': '[0001] 쿠폰',
  '0002': '[0002] 바우처',
  '0010': '[0010] 포인트',
  '0011': '[0011] 포인트|쿠폰',
  '0100': '[0100] 카드',
  '0101': '[0101] 카드|쿠폰',
  '0110': '[0110] 카드|포인트',
  '0111': '[0111] 카드|포인트|쿠폰',
  '2000': '[2000] 후불',
  '1001': '[1001] 현금|쿠폰',
  '1010': '[1010] 현금|포인트',
  '1011': '[1011] 현금|포인트|쿠폰',
  '1100': '[1100] 현금|카드',
  '1101': '[1101] 현금|카드|쿠폰',
  '1110': '[1110] 현금|카드|포인트',
  '1111': '[1111] 현금|카드|포인트|쿠폰',
}


//----------------------------------------------------------------------------------------------------
export default {
  name: 'SalesOrder',
  data() {
    return {
      isSending: false,
      perPage: 20,
      prdCodeOpts: {},
      selectedPrd: null,
      prdOpts: [],
      prdInfo: {},
      searchField: 'userId',
      searchOpts: [
        {value: 'userId', text: '아이디'},
        {value: 'name', text: '이름'},
        {value: 'compName', text: '회사명'},
        {value: 'compNo', text: '사업자번호'},
        // {value: 'loginId', text: '이용자아이디'},
        {value: 'email', text: '이메일'},
        {value: 'phoneNo', text: '전화번호'},
        {value: 'prdCode', text: '제품코드'}
      ],
      dayOpts: [
        {value: 7, text: '1주일'},
        {value: 14, text: '2주일'},
        {value: 30, text: '30일'},
        {value: 90, text: '90일'},
        {value: 180, text: '180일'},
        {value: null, text: '전체'},
      ],
      dateGbn: "reg",
      dateGbnOpt: [
        {value: 'reg', text: '등록일'}
        // , {value: 'ord', text: '주문일'}
        // , {value: 'appr', text: '승인일'}
        // , {value: 'pay', text: '결제일'}
        // , {value: 'send', text: '전송일'}
        // , {value: 'tax', text: '계산서발행일'}
        // , {value: 'upd', text: '수정일'}
      ],
      payTypeOpts,
      taxStatOpts: {
        '99': '미발행',
        '01': '영수계산서대상',
        '02': '청구계산서대상',
        '03': '현금영수증대상',
        '04': '영수증발행대상',
        '11': '영수발행',
        '12': '청구발행',
        '13': '현금영수증발행',
        '14': '영수증발행'
      },
      payStatOpts: {'99': '미결제', '10': '부분결제', '20': '초과결제', '00': '결제완료', '09':'결제취소', '19':'부분결제취소', '29':'초과결제취소'},
      sendStatOpts: {'99': '미발송', '10': '구매확인', '20': '발송준비', '90': '발송에러', '00': '발송완료'},
      isTaxOpts: {'Y': '발행완료', 'N': '미발행'},

      statusOpt: {'00': '활성', '10': '준비', '90': '추출', '99': '만료'},
      logTypeOpts: {'I': '접속', 'O': '종료', 'S': '설치'},
      progCount: 0,
      totalCount: 0,

      rows: [],
      claimLogs: [],
      selectedIdx: null,
      selectedRow: null,
      fromDate: moment().format("YYYY-MM-01"),
      toDate: moment(this.fromDate).endOf("month").format("YYYY-MM-DD"),

      // fromDate: moment().subtract( 7,"days").format('YYYY-MM-DD'),
      // toDate: moment().format('YYYY-MM-DD'),
      /////////////////////////////////////////////////////
      searchWord: null,
      paramMap: {},
      dt1: moment().format('YYYY-MM-DD'),
      dt2: moment().format('YYYY-MM-DD'),
      diffDt: 0,
      order: null,
      ordFields: [
        { key: '_id'          , label: '주문번호', tdClass:'text-danger', formatter: v=>{return v.toUpperCase().slice(-6)} },
        { key: 'createdAt'    , label: '주문일시', formatter: v=>{return moment(v).format('YYYY-MM-DD HH:mm')}},
        { key: 'salesMaster'  , label: '상품정보', formatter: v=>{return v? `[${v.code}] ${v.name} | ${v.title}`: '' }},
        { key: 'products'     , label: '주문제품'},
        { key: 'amount'       , label: '주문금액', formatter: v=>{ return v? commify(v):''}},
        { key: 'userId'       , label: '아이디'},
        { key: 'pgTran'       , label: '결제정보'},
        { key: 'payType'      , label: '결제방식'},
        { key: 'payStat'      , label: '결제상태'},
        { key: 'payAmount'    , label: '결제금액', formatter: v=>{ return v? commify(v):''}},
        { key: 'approveYn'    , label: '승인'},
        { key: 'approveDt'    , label: '승인일시'},
        { key: 'email'        , label: '이메일'},
        { key: 'name'         , label: '주문자명'},
        { key: 'compName'     , label: '회사명'},
        { key: 'prName'       , label: '대표자명'},
        { key: 'bizKind'      , label: '업종'},
        { key: 'compNo'       , label: '사업자번호'},
        { key: 'taxStat'      , label: '계산서구분'},
        { key: 'description'  , label: '요청내용'},
        { key: 'cancelYn'     , label: '취소여부'},
        { key: 'cancelDt'     , label: '취소일시'},
        { key: 'cancelDesc'   , label: '취소사유'},
        // { key: 'comment'      , label: '취소사유'},
        { key: 'button'       , label: '주문승인'},
      ],
      prdFields: [
        { key: 'prd_code'     , label: '제품코드', tdClass: 'text-nowrap'},
        { key: 'disp_name'    , label: '제품명', tdClass: 'text-nowrap' },
        { key: 'unit_price'   , label: '금액(원가)', tdClass:'text-right',  formatter: v=>{ return v? commify(v):''} },
        { key: 'discount_price', label: '할인금액', tdClass:'text-right',  formatter: v=>{ return v? commify(v):'0'} },
        // { key: 'qty'          , label: '수량'},
        { key: 'warranty_mon' , label: '사용(월)'},
        { key: 'confirmYn'    , label: '승인'},
        { key: 'confirmDt'    , label: '승인일시', tdClass: 'text-nowrap', formatter: v=>{ return v? this.toYmdHm(v):''}},
        // { key: 'userLicense'  , label: '라이센스ID'},
        { key: 'apprBtn'      , label: '승인', tdClass: 'text-nowrap'},
        { key: 'sendBtn'      , label: '메일', tdClass: 'text-nowrap'},
      ],
      licenses: [],
      addLvDay: 0,
      addMaDay: 0,
      license: null,
      licenseFields: [
        {key: 'level', label: '코드'},
        {key: 'prdName', label: '라이센스 명', tdClass: 'text-nowrap'},
        {key: 'lvEndDt', label: '기간', formatter: v=>{ return v? this.toYmd(v):''}},
        {key: 'maEndDt', label: 'MA기간', formatter: v=>{ return v? this.toYmd(v):''}},
        {key: 'createdAt', label: '등록일', formatter: v=>{ return v? this.toYmd(v):''}},
      ],
      payment: null,
      payments: [],
      paymentFields: [
        {key: 'Moid',      label: '주문번호', formatter: v=>{return v?v.slice(-6).toUpperCase():'N/A'}},
        {key: '_id',      label: '결제번호', formatter: v=>{return v?v.slice(-6).toUpperCase():'N/A'}},
        {key: 'PayMethod', label: '지불수단' },
        {key: 'fn_name',   label: '카드사' },
        {key: 'CardNum',   label: '카드번호' },
        {key: 'CardQuota', label: '할부' },
        {key: 'Amt',       label: '거래금액', formatter: v=> {return commify(v)} },
        {key: 'cancelYn', label: '취소여부' },
        // {key: 'TID',       label: '거래번호' },
        {key: 'AuthDate',  label: '거래일시' },
        {key: 'AuthCode',  label: '승인번호' },
        {key: 'ResultMsg', label: '거래결과' },
        {key: 'cancelDt', label: '취소일시', formatter: v=>{ return v? this.toYmdHm(v):''} }
      ],
      isCancelProc: false
    }

  },
  async created() {
    try {
      // this.paramMap['dateGbn'] = this.dateGbn;
      this.paramMap['toDate'] = this.toDate;
      this.paramMap['fromDate'] = this.fromDate;
      await this.getPrdCode();
      await this.getSalesOrderList();
    } catch (err) {
      console.log(err);
    }
  },
  computed: {
    flattenedData() {
      this.rows.forEach( row => {
        if (row.business) {
          row.compName = row.business.compName;
          row.prName   = row.business.prName;
          row.bizKind  = row.business.bizKind;
          row.compNo   = row.business.compNo;
          row.address  = row.business.address;
        }
        if (!row.taxInvoiceYn) row.taxInvoiceYn = 'N';
      });
      return this.rows;
    }
  },

  mounted() {
    console.log("mounted---------------------ProductToken");
    // below is not work!
  },

  methods: {
    toYmdHm(v){
      return v?moment(v).format('YY/MM/DD HH:mm'):'';
    },
    toYmd(v){
      return v?moment(v).format('YY/MM/DD'):'';
    },
    toComma(v){
      return v? commify(v):0;
    },
    toNumber(v){
      return v? numberfy(v):0
    },

    async getPrdCode() {
      console.log("getPrdCode ---- 1");
      this.prdCodeOpts = {};
      this.prdOpts = [];
      this.prdOpts.push({value: null, text: '주문상품 선택'});
      try {
        const r = await apiCall('get', `/admin/api/product/info`);

        r.result.map(i => {
          this.prdCodeOpts[i.prd_code] = i.disp_name;
          this.prdInfo[i.prd_code] = i;
          this.prdOpts.push({value: i, text: i.prd_code + ' | ' + i.disp_name, disabled: (i.use_yn !== "Y")});
        });
        // console.log("prdCodeOpts ----> ", this.prdCodeOpts);
        // console.log("prdOpts ----> ", this.prdOpts);
        // console.log("prdInfo ----> ", this.prdInfo);

      } catch (err) {
        console.log(err);
      }
    },

    async getSalesOrderList() {
      this.paramMap = [];
      this.$refs['orderGrid'].clearFilter();
      let r = null;
      let qry = '';
      this.paramMap['fromDate'] = this.fromDate;
      this.paramMap['toDate'] = this.toDate;
      this.paramMap['payStatP'] = '00';
      this.paramMap['payTypeS'] = '1';
      this.paramMap['taxStat'] = '01';
      if (this.searchWord) this.paramMap[this.searchField] = this.searchWord;
      qry = qs.stringify(this.paramMap);
      console.log("query-string ---------- ", qry);
      try {
        this.rows = [];
        r = await apiCall('get', `/admin/api/sales/order?${qry}`);
        // console.log( "getSalesOrderList ----------> ", r);
        if (r.result) {
          this.rows = r.result;
          await toastSync(this.$bvToast, `${this.rows.length}건 조회됨`, 'primary');
        } else {
          await toastSync(this.$bvToast, `에러: ${r.code} ${r.message}`, 'danger');
        }

      } catch (err) {
        console.log(err);
      }

    },
    setDateGbn() {
      // this.paramMap['dateGbn'] = this.dateGbn;
      // this.getSalesOrderList();
    },

    exportExcel() {
      const format = 'xlsx';
      const exportSelectedOnly = true;
      const filename = 'product-order-' + moment().format('YYYY-MM-DD');
      this.$refs["orderGrid"].exportTable(format, exportSelectedOnly, filename);
    },

    async updateSalesOrder(rec) {
      let rc = rec[0];
      if (!rc.keys[0]) {
        console.log("nokey");
        return;
      }
      const tIndex = this.$refs['orderGrid'].rowIndex[rc.$id];
      console.log("updateSalesOrder--------- rec[0]|rc --->", rc);
      console.log("updateSalesOrder--------- rec[0].$id--->", rc.$id);
      console.log("updateSalesOrder--------- rec[0].$id--->", this.$refs['orderGrid']);
      console.log("updateSalesOrder--------- rowIndex--->", tIndex);
      let row = this.$refs['orderGrid'].table[tIndex];

      console.log("row ---------> ", row);

      let r = null;

      try {
        let param = {};
        const fieldName = rc.name;
        let newVal = rc.newVal;

        if (row.approveYn === 'Y') {
          if (!(await alertConfirm(this.$bvModal, "승인된 주문을 수정합니다. 계속하시겠습니까?", '승인 주문 수정'))) {
            row[rc.name] = rc.oldVal;
            return;
          }
        }

        if (fieldName === "compNo") {
          const compNo = newVal.replace(/[^0-9]/g, '');
          console.log(compNo);
          if (!checkBisNo(compNo)) {
            await toastSync(this.$bvToast, `사업자번호 검증 실패: ${compNo}`);
          }
          newVal = compNo;
          row.compNo = compNo;
        } else if (fieldName === 'payType') {
          if (newVal !== '9999' && newVal !== '0000') {
            row.payAmount = row.price;
            row.payStat = '00'; // 결제완료
            row.payDt = moment().toISOString();
            param['payAmount'] = row.payAmount;
            param['payStat'] = row.payStat;
            param['payDt'] = row.payDt;
          }
        }

        param[fieldName] = newVal;

        r = await apiCall("PUT", `/admin/api/sales/order/${row._id}`, param);
        if( r.code===200){
          await toastSync(this.$bvToast, `수정 되었습니다.`, 'success');
          await this.getSalesOrderList();
        }else{
          await toastSync(this.$bvToast, `수정 실패`, 'danger');
        }
        console.log(r);

      } catch (err) {
        console.log(err);
      }
    },

  }
}
</script>
